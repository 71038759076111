<template>
  <div>
    <b-modal v-model="showActionModal" @ok="userAction(action, false, true)">
      <p> {{ modalText }} </p>
    </b-modal>

    <b-modal
      v-model="showAddUserModal"
      centered
      ok-only
      :ok-title="t.t('registration.add')"
      @ok="addUser"
    >
      <div>
        <h1 class="d-flex justify-content-center mb-2">
          {{ t.t("registration.add-user") }}
        </h1>
        <div>
          <!-- basic -->
          <b-form-group
            :label="t.t('registration.name')"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              v-model="addUserData.userName"
              :placeholder="t.t('registration.name')"
            />
          </b-form-group>
        </div>
        <div>
          <!-- basic -->
          <b-form-group
            :label="t.t('registration.email')"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              v-model="addUserData.email"
              :placeholder="t.t('registration.email')"
            />
          </b-form-group>
        </div>
        <div>
          <!-- basic -->
          <b-form-group
            :label="t.t('registration.mobile')"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              v-model="addUserData.mobile"
              :placeholder="t.t('registration.mobile')"
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>

    <b-card
      :header="$i18n.t('navigation.user-management')"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <div class="buttonsWrapper">
        <b-button variant="primary" class="mb-2" @click="showAddUserPopup">
          {{ t.t("global.add") }}
        </b-button>
      </div>
      <!-- table -->
      <b-table
        sticky-header
        :fields="columns"
        :items="users"
        bordered
        responsive
        hover
        class="products-table shadow-sm rounded"
        show-empty
        :empty-text="$t('global.no-items')"
        small
      >
        <template #cell(action)="data">
          <b-button
            variant="flat-primary"
            class="btn-icon"
            @click="userAction('reset', data.item, false)"
          >
            <feather-icon size="16" icon="EditIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="userAction('delete', data.item, false)"
          >
            <feather-icon size="16" icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BFormGroup,
  BFormInput,
  BTable,
  BButton,
  BCard,
} from "bootstrap-vue";
import store from "@/store/index";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BCard,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      isOpen: false,
      showActionModal: false,
      showAddUserModal: false,
      action: null,
      resetUserId: null,
      addUserData: {},
      searchTerm: "",
    };
  },
  computed: {
    ...mapState("userManagement", ["users"]),
    columns() {
      return [
        {
          label: this.$i18n.t("products.actions"),
          key: "action",
        },
        {
          label: "ID",
          key: "displayId",
        },
        {
          label: this.$i18n.t("users.username"),
          key: "userName",
        },
        {
          label: this.$i18n.t("users.email"),
          key: "email",
        },
        {
          label: this.$i18n.t("users.mobile"),
          key: "mobile",
        },
      ];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    tableTheme() {
      if (store.state.appConfig.layout.skin === "dark") {
        return "nocturnal";
      }
      return "";
    },
  },
  created() {
    this.getUsers();
    this.t = this.$i18n;
  },
  methods: {
    ...mapActions("userManagement", [
      "getUsers",
      "registerUser",
      "resetUserPassword",
      "deleteUser",
    ]),
    showPopup() {
      this.showActionModal = true;
    },
    showAddUserPopup() {
      this.showAddUserModal = true;
    },
    addUser() {
      this.registerUser({
        userName: this.addUserData.userName,
        email: this.addUserData.email,
        mobile: this.addUserData.mobile,
      }).then(() => {
        this.addUserData = {};
        this.getUsers();
      });
    },
    userAction(action, item, ok) {
      switch (action) {
        case "reset":
          if (item.id) {
            this.modalText = `${item.email} - ${this.$t("userManagement.resetPassword")}`;
            this.resetUserId = item.id;
            this.action = "reset";
          }
          if (ok) {
            this.resetUserPassword({
              id: this.resetUserId,
            });
            this.resetUserId = null;
            this.action = null;
          } else {
            this.showPopup();
          }
          break;
        case "delete":
          if (item.id) {
            this.modalText = `${item.email} - ${this.$t("userManagement.deleteUser")}`;
            this.action = "delete";
            this.resetUserId = item.id;
          }
          if (ok) {
            this.deleteUser({
              id: this.resetUserId,
            }).then(() => {
              this.getUsers();
            });
          } else {
            this.showPopup();
          }
          break;
        default:
        // code block
      }
    },
  },
};
</script>
<style lang="scss">
.products-table th,
.products-table td {
  // min-width: 200px ;
  white-space: nowrap;
}
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}

.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
</style>
